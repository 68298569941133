<template>
    <div>
        <div class="contact u-text-highlight p-4 mt-5 mb-3 text-center h4 img--framed">
            Dir gefallen meine Projekte? Du bist auf der Suche nach jemandem mit Programmierkenntnissen? Dann schreib mir eine E-Mail oder schreib mir auf Bluesky :)
        </div>
        <div class="meta text-center mb-5">
            <Socials />
        </div>
    </div>
</template>

<script>
import Socials from "./Socials"

export default {
    name: 'Contact',
    components: { Socials },
}
</script>
