<template>
    <div class="row">
        <div class="col-12 col-lg-9 mb-3">
            <Slogan />
        </div>
        <div class="col-12 mb-4 d-md-none">
            <div class="logo-wrapper logo-wrapper--fullwidth">
                <img src="../assets/nhb_goat.gif" alt="Logo von Nick hat Böcker im Pixelstil: Ein Ziegenbock, darunter die Abkürzung NHB" title="" width="20%" height="auto" />
            </div>
        </div>
        <div class="col-12 text-lg-center">
            <div class="row facts-row">
                <template v-for="(fact, index) in facts">
                    <Fact class="col-6 col-lg-3 mb-3 facts" :key="`fact-${index}`" v-bind="fact" />
                </template>
            </div>
        </div>
        <div class="col-12">
            <ImageStripe />
        </div>
    </div>
</template>

<script>
import Fact from './Fact'
import Slogan from './Slogan'
import ImageStripe from "./ImageStripe";

export default {
    name: 'Header',

    components: { ImageStripe, Slogan, Fact },

    computed: {
        facts () {
            return this.$store.state.facts
        },
    },
}
</script>

<style lang="scss" scoped>
.facts-row {
    border-top: 1px solid $primary;
    padding-top: $spacing-unit * 5;
}
</style>
